.header-link ul {
  display: inline;
  list-style: none;
  text-align: center;
}

.header-link {
  padding-top: 40px;
  text-align: center;
}

.header-link ul li {

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  /* or 18px */

  color: #FF098E;

  margin: 0 20px;
  display: inline;
  cursor: pointer;
  list-style: none;
  text-align: center;
}

.active-link {
  padding: 12px 30px;
  color: #FFFFFF !important;

  background: #FFA800;
  border-radius: 27.5px
}