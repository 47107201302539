.side-logo img {
    width: 220px;
}

.side-logo {
    position: absolute;
    top: 25px;
    left: 28px;

    /* margin-top: 20px;
    margin-left: 30px; */
}

.staking-btn {
    margin-top: 60px;
    text-align: center;
}

.staking-btn button {
    border-radius: 50px;
    cursor: pointer;
    margin: 0 10px;
    border: none;
    padding: 8px 20px;
    width: 250px;
    outline: none;
    color: white;
    background-color: #182A42;
    font-family: 'Jockey One', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
}

.cards {
    display: flex;
    flex-wrap: wrap;
    width: 85vw;
    height: 70vh;
    overflow: auto;
    margin: 0 auto;
    justify-content: center;
    margin-top: 30px;
}

.single-card {
    width: 22%;
    margin: 10px 15px;
    /* height: 100px; */
}

.card-detail {
    margin-top: -15px;
    background: #0B131D;
    min-height: 120px;
    padding: 15px;
    box-sizing: border-box;
}

.card-detail .title {
    /* padding-top: 15px; */
    padding-left: 5px
}

.card-detail h3 {
    font-family: 'Jockey One';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 42px;

    color: #FFFFFF;
    margin-left: 15px;
    display: inline;
}

.card-data {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.card-data {
    padding: 5px 10px !important
}

.thumbnail {
    position: relative;
}

.lvl {
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    margin-top: 10px;
    margin-left: 10px;
    background: #0B131D;
    border-radius: 5px;
    padding: 3px 10px;
    color: white;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;

    color: #FFFFFF;

}

.thumbnail img {
    width: 100%;
}

.card-data p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    text-align: center;

    color: #5E6671;

}

.card-data div h5 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    text-align: center;

    color: #FFFFFF;


}

.view-btn {
    margin-top: 5px;
    text-align: center;
}

.view-btn button {
    text-align: center;
    border: none;
    margin: 0 auto;
    cursor: pointer;
    width: 87%;
    padding: 4px 0;
    text-transform: uppercase;
    color: white;
    background: #182A42;
    border-radius: 50px;

    font-family: 'Jockey One';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
}



/* width */
.cards::-webkit-scrollbar {
    width: 4px;
    display: block;
}

/* Track */
.cards::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(255, 255, 255);
}

/* Handle */
.cards::-webkit-scrollbar-thumb {
    background: #FD028A;
}

@media(max-width:1200px) {
    .cards {
        display: flex;
        flex-wrap: wrap;
        width: 90vw;
    }

    .single-card {
        width: 35%;
    }
}