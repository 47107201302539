.market-card {
    direction: ltr;

    cursor: grab;
    display: block;
    /* width: 90vw; */
    height: auto;
    overflow: auto;
    /* margin: 0 auto; */



    white-space: nowrap;
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
}

.main-wrapper {
    direction: rtl;
    height: 70vh;
    padding-left: 50px;
    float: right;
    width: 90vw;
    margin-top: 80px !important;
    padding-bottom: 50px;

    overflow: auto;

}

.market-card .single-card {
    display: inline-block;
}

.card-head {
    padding-left: 50px;
}

.card-head h1 {
    font-family: 'Itim', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 77px;
    text-align: left;

    color: #FF0321;
    text-shadow:
        -1px -1px 0px #FFAE02,
        0px -1px 0px #FFAE02,
        1px -1px 0px #FFAE02,
        -1px 0px 0px #FFAE02,
        1px 0px 0px #FFAE02,
        -1px 1px 0px #FFAE02,
        0px 1px 0px #FFAE02,
        1px 1px 0px #FFAE02;
    /* border: 2px solid #FFAE02; */
}

.market-card .single-card .card-detail {
    border-radius: 0 0 30px 30px;
}

.market-card .single-card .thumbnail img {
    border-radius: 30px 30px 0 0;
}

/* width */
.main-wrapper::-webkit-scrollbar {
    width: 4px;
    display: block;
}

/* Track */
.main-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(255, 255, 255);
}

/* Handle */
.main-wrapper::-webkit-scrollbar-thumb {
    background: #FD028A;
}