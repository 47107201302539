 /* @font-face {
     font-family: 'Poppins';
     src: url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,700;1,800&display=swap');
 } */

 body {
     padding: 0;
     font-family: 'Poppins', sans-serif;
     margin: 0;
     box-sizing: border-box;
 }

 ::-webkit-scrollbar {
     display: none;
 }



 p, h3, h2, h1, h4, h5, h6 {
     padding: 0;
     margin: 0;
 }


 .icon-bg {
     display: flex;
     flex-direction: row;
     justify-content: center;
     position: absolute;
     bottom: 0;
     background-repeat: no-repeat;
     background-size: cover;
     margin: 0 auto;
     margin-bottom: 0px;
     /* background-image: url(../../assets/img/icon-bg.png); */
     background-color: #FD028A;
     box-shadow: 0px 13px 30px rgba(0, 91, 227, 0.3);
     border-radius: 30px;
     padding: 15px;
     left: 50%;
     z-index: 99;
     transform: translate(-50%, -50%);
 }

 .mplace {
     font-size: 10px !important;
 }

 .icon-bg img {

     width: 50px;
 }

 .ico-bg {

     display: inline;

 }


 .middle-ico {
     border-left: 2px solid rgba(255, 245, 0, 1);
     border-right: 2px solid rgba(255, 245, 0, 1);

     /* box-shadow: #ff0321 1px -1px 20px 0px, #ff0321 0px 1px 11px 0px, #ff0321 2px -2px 80px 0px;
     background: #ff0321b5; */
 }

 .middle-icon {
     box-shadow: #ff0321 2px -3px 20px 4px, #ff0321 -9px 10px 20px 7px, #ff0321 20px 9px 80px 16px;
     background: #ff0321;
     /* box-shadow: #ff0321 -20px 0px 20px, #ff0321 20px 0px 56px 0px, #ff0321 -20px 1px 80px 0px;
     box-shadow: #FF0321;
     filter: blur(20px); */
     /* box-shadow: #FF0321 0px 22px 70px 4px; */
 }

 .side-icon {
     opacity: 0.4;
 }


 .icon-area {
     padding: 0 30px;
     text-align: center;
 }

 .sp {
     padding-right: 23px !important;
 }

 .icon-area p {
     font-family: 'Poppins', sans-serif;
     font-style: normal;
     font-weight: 500;
     font-size: 14px;
     /* or 18px */

     text-align: center;
     margin: 0;
     color: #FFFFFF;
 }

 .logo img {
     width: 400px;
 }

 .logo {
     margin: 40px 0;
     text-align: center;
 }

 .mint-counter {
     display: flex;
     /* box-shadow: rgb(174, 20, 247) -7px 7px 19px 0px; */
     flex-direction: row;
     justify-content: center;
     width: max-content;
     margin: 0 auto;
     /* 
     background-color: rgba(174, 20, 247, 0.7); */

 }

 .counter-bg {
     border-radius: 16px;
     /* display: flex; */
     box-shadow: rgb(174, 20, 247) -7px 7px 19px 0px;
     /* flex-direction: row; */
     justify-content: center;
     width: max-content;
     margin: 0 auto;

     background-color: rgba(174, 20, 247, 0.7);
 }

 .increment {
     display: flex;
     align-items: center;
     cursor: pointer;
     padding: 10px 10px;
     background: #6C26FF;
     border: 1px solid #000000;
     filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
     border-radius: 16px 0px 0px 16px;
 }

 .decrement {
     cursor: pointer;
     display: flex;
     align-items: center;

     padding: 10px 10px;
     background: #6C26FF;
     border: 1px solid #000000;
 }

 .digit {
     background-color: white;
     border-top: 1px solid #000000;
     border-bottom: 1px solid #000000;
     padding: 10px 45px;

     font-family: 'Poppins';
     font-style: normal;
     font-weight: 600;
     font-size: 20px;

     text-align: center;

     color: #000000;


 }

 .ico-id {
     width: 13px;
 }

 .mint-btn button {
     color: white;
     border: none;
     background-color: transparent;

     font-family: 'Poppins';
     font-style: normal;
     font-weight: 600;
     font-size: 16px;
     display: flex;
     align-items: center;
     text-align: center;

     cursor: pointer;
     color: #FFFFFF;
 }

 .mint-btn {
     cursor: pointer;
     display: flex;

     background: linear-gradient(180deg, #167FE0 0%, #A016E0 100%);
     border-width: 1px 1px 1px 0px;
     padding: 10px 10px;
     border-style: solid;
     border-color: #000000;
     border-radius: 0px 16px 16px 0px;
 }

 .mint-details {
     margin-top: 50px !important;
     background: rgba(174, 20, 247, 0.7);
     border-radius: 42px;
     width: 45vw;

     padding: 20px 25px 50px 25px;
     margin: 0 auto;
 }

 .mint-details h3 {
     font-family: 'Poppins';
     font-style: normal;
     font-weight: 700;
     font-size: 40px;
     /* or 84px */

     text-align: center;

     color: #FFFFFF;

 }

 .mint-address {
     list-style: none;
     padding: 10px;
 }

 .mint-address li span {
     font-family: 'Poppins';
     font-style: normal;
     font-weight: 600;
     font-size: 20px;


     color: #FFFFFF;
 }

 .mint-address li {
     font-size: 18px;

     color: white;
     list-style: none;
 }