.mint-pg {
    background-image: url(../assets/img/Mint.png);
    /* background-size: 100%; */

    /* background-position: bottom right; */

    min-height: 100vh;


    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
}

.stake-pg {
    background-image: url(../assets/img/Stake.png);
    /* background-size: 100%; */

    /* background-position: bottom right; */

    min-height: 100vh;


    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
}

.market-bg {

    background-image: url(../assets/img/Marketplace.png);
    /* background-size: 100%; */

    /* background-position: bottom right; */

    min-height: 100vh;


    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
}

/* @media (min-width:1200px) {
    .mint-pg {


        min-height: 140vh;

    }

    .stake-pg {


        min-height: 140vh;

    }


}

@media (min-width:1600px) {
    .mint-pg {


        min-height: 130vh;
    }

    .stake-pg {


        min-height: 130vh;
    }


}

@media (min-width:1700px) {
    .mint-pg {


        min-height: 100vh;
    }



    .stake-pg {


        min-height: 100vh;
    }
} */